import Service from '@/helpers/service'

export default {

    get(page, params) {
        return Service().get('ProductHierarchy?page=' + page, {params});
    }, 
    show(id) {
        return Service().get('ProductHierarchy/show/'+id);
    },    
    create(params) {
        return Service().post('ProductHierarchy/create', params);
    },
    update(id, params) {
        return Service().post('ProductHierarchy/update/'+id, params);
    },
    delete(id) {
        return Service().post('ProductHierarchy/delete/'+id);
    }
}